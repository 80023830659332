<template>
    <div class="paper">
        <div class="header">
            <div class="header-title">
                电子视力档案
            </div>
            <div class="header-info">
                <span>
                    {{ childInfo.obj.cname }}
                </span>
                <span>
                    {{ childInfo.obj.sex == 1 ? '男' : '女' }}
                </span>
                <span>
                    {{ formatAge(childInfo.obj.age) }}
                </span>
                <span>
                    {{ childInfo.obj.height + 'cm' }}
                </span>
                <span>
                    {{ childInfo.obj.weight + 'kg' }}
                </span>

                <!-- <span>
                    {{ childInfo.shili }}
                </span>
                <span>
                    {{ childInfo.semang == "无" ? '': childInfo.semang }}
                </span>
                <span>
                    {{ childInfo.ruoshi == "无" ? '': childInfo.ruoshi }}
                </span> -->
               
                 <el-button icon="el-icon-plus"  type="primary" @click="add()" class="add">新增</el-button >
               
            </div>
           
        </div>
        <div class="table">
            <table style="table-layout:fixed;word-break:break-all;">
                <tr>
                    <th class="wid84">时间</th>
                    <th class="wid50">类型</th>
                    <th class="wid40">R/L</th>
                    <th class="wid50">裸眼</th>
                    <th class="wid70">球镜</th>
                    <th class="wid70">柱镜</th>
                    <th class="wid50">矫正</th>
                    <th class="wid50">眼轴</th>
                    <th class="wid74">月化增速</th>
                    <th class="wid50">轴余</th>
                    <th style="width: 75px;">K1 / K2</th>
                    <th class="wid74">角散</th>
                    <th class="wid50">眼压</th>
                    <th class="wid80">地点设备</th>
                    <th >防控方法</th>
                    <th >备注</th>
                    <th style="width: 175px;">操作</th>
                </tr>
                <tr v-for="(item, index) in list" :key="index">
                    <td>
                        {{ item.checktime.substr(0,4) }}-
                        {{ item.checktime.substr(5) }}
                    </td>
                    <td>{{ item.sta == 1 ? '小瞳' : '散瞳' }}</td>
                    <td>
                        <p>R</p>
                        <p>L</p>
                    </td>
                    <td>
                        <p>{{ item.裸眼[1] }}</p>
                        <p>{{ item.裸眼[0] }}</p>
                    </td>
                    <td>
                        <p>{{ item.球镜[1] }}</p>
                        <p>{{ item.球镜[0] }}</p>
                    </td>
                    <td>
                        <p>{{ item.柱镜[1] }}</p>
                        <p>{{ item.柱镜[0] }}</p>
                    </td>
                    <td>
                        <p>{{ item.矫正视力[1] || '' }}</p>
                        <p>{{ item.矫正视力[0] || '' }}</p>
                    </td>
                    <td>
                        <p>{{ item.眼轴[1] || '' }}</p>
                        <p>{{ item.眼轴[0] || '' }}</p>
                    </td>
                    <td>
                        <p>{{ (item.月化[1] && item.月化[1] > 0 ? '+' :'') + item.月化[1] ? item.月化[1] : '' }}</p>
                        <p>{{ (item.月化[0] && item.月化[0] > 0 ? '+' :'') + item.月化[0] ? item.月化[0] : '' }}</p>
                    </td>
                    <td>
                        <p>
                            <span v-if="item.轴余">
                                {{ item.轴余[1] || '' }}
                            </span>
                        </p>
                        <p>
                            <span v-if="item.轴余">
                                {{ item.轴余[0] || '' }}
                            </span>
                        </p>
                    </td>
                    <td>
                        <p>
                            <span v-if="item.k1[1]">
                                {{ (item.k1[1] || '') + '/' + (item.k2[1] || '') }}
                            </span>
                        </p>
                        <p>
                            <span v-if="item.k1[0]">{{ (item.k1[0] || '' )+ '/' + (item.k2[0] || '') }}</span>
                        </p>
                    </td>
                    <td>
                        <p>
                            <span v-if="item.角膜散光">
                                {{ item.角膜散光[1] || '' }}
                            </span>
                        </p>
                        <p>
                            <span v-if="item.角膜散光">
                                {{ item.角膜散光[0] || '' }}
                            </span>
                        </p>
                    </td>
                    <td>
                        <p>
                            <span v-if="item.眼压">
                                {{ item.眼压[1] || '' }}
                            </span>
                        </p>
                        <p>
                            <span v-if="item.眼压">
                                {{ item.眼压[0] || '' }}
                            </span>
                        </p>
                    </td>
                    <td>{{ item.htype }}</td>
                    <td>{{item.devids || ''}}</td>
                    <td>{{item.remark || ''}}</td>
                    <td>  <el-button type="warning"  icon="el-icon-edit " @click="handleEdit(item.id)"
                             size="small" >修改</el-button > </td>
                             
                </tr>
            </table>
        </div>
        <div class="charts">
            <div class="chart">
                <div class="chart-title">
                    眼轴发育趋势
                </div>
                <div class="chart-view" id="yzChart">
                </div>
            </div>
            <div class="chart">
                <div class="chart-title">
                    屈光度趋势
                </div>
                <div class="chart-view" id="qgdChart">
                </div>
            </div>
            <div class="chart">
                <div class="chart-title">
                    裸眼视力趋势
                </div>
                <div class="chart-view" id="lyslChart">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { innitChart2 } from "./echart";
    import dayjs from 'dayjs';
    import html2canvas from 'html2canvas';
    export default {
        data() {
            return {
                ready: false,
                kiid: '',
                birthdata:"",
                childInfo: {
                    ruoshi: '',
                    semang: '',
                    shili: '',
                    obj: {
                        cname: '',
                        sex: '',
                        age: '',
                        height: '',
                        weight: '',
                    }
                },
                lyslobj: {
                    ages: [],
                    series: []
                },
                qgdobj: {
                    ages: [],
                    series: []
                },
                yzobj: {
                    ages: [],
                    series: []
                },
                list: [],
                showImg: false,
                shareImg: '',
                showShare: false,
                options: [
                    // { name: '微信', icon: 'wechat' },
                    // { name: '微博', icon: 'weibo' },
                    // { name: '复制链接', icon: 'link' },
                    // { name: '分享海报', icon: 'poster' },
                    // { name: '二维码', icon: 'qrcode' },
                ],
            }
        },
        mounted() {
            this.kiid = this.$route.query.kiid;
            this.birthdata = this.$route.query.birthdata;
            console.log("mounted=>kiid",this.kiid);
            this.init();
            // this.postlist();
        },
        methods: {


            add(){
                this.$router.push({
                path: "./information",
                query: { kiid: this.kiid ,birthdata:this.birthdata},
                    });
            },
            handleEdit(id) {
                this.$router.push({
                path: "./oldinformation",
                query: { id: id,kiid:this.kiid },
                });
            },


            formatAge(val) {
                return val ? Math.floor(val / 12) + '岁' + ((val % 12) != 0 ? (val % 12) + '个月' : '') : ''
            },
            get_child_info() {
                return new Promise((resolve, reject) => {
                    this.$api.vipuser.shareInfo({ kiid: this.kiid }).then(res => {
                        console.log(res);
                        console.log("shareInfo");
                         let obj = JSON.parse(res.obj.info)
                        //  let obj = res
                        // document.title = obj.cname + '的电子视力档案'
                        // this.childInfo.obj = obj;


                        this.childInfo.obj.cname = obj.cname;
                        this.childInfo.obj.sex = obj.sex;
                        this.childInfo.obj.age = obj.age;
                        if (obj.height==null) {
                            this.childInfo.obj.height="__"
                        } else {
                             this.childInfo.obj.height = obj.height;
                        }
                           if (obj.weight==null) {
                            this.childInfo.obj.weight="__"
                        } else {
                             this.childInfo.obj.weight = obj.weight;
                        }
                
                        
                        // this.childInfo.ruoshi = res.obj.弱视
                        // this.childInfo.semang = res.obj.色盲
                        // this.childInfo.shili = res.obj.视力
                        this.ready = true;
                        resolve();
                    })
                })
            },
            ly() {
                return new Promise((resolve, reject) => {
                    this.$api.vipuser.ly({ kiid: this.kiid }).then(res => {
                        this.lyslobj = {
                            ages: res.obj.age,
                            series: [
                                {
                                    name: '左眼',
                                    type: 'line',
                                    smooth: true,
                                    data: res.obj.lyl,
                                    itemStyle: {
                                        color: '#698edc',
                                    },
                                    lineStyle: {
                                        color: '#698edc',
                                        width: 2,
                                    },
                                    areaStyle: {
                                        color: {
                                            type: 'linear',
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1,
                                            colorStops: [{
                                                offset: 0, color: '#698edc' // 0% 处的颜色
                                            }, {
                                                offset: 1, color: '#fff' // 100% 处的颜色
                                            }],
                                            global: false // 缺省为 false
                                        }
                                    }
                                },
                                {
                                    name: '右眼',
                                    type: 'line',
                                    smooth: true,
                                    data: res.obj.lyr,
                                    itemStyle: {
                                        color: '#ee6666',
                                    },
                                    lineStyle: {
                                        color: '#ee6666',
                                        width: 2,
                                    },
                                    areaStyle: {
                                        color: {
                                            type: 'linear',
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1,
                                            colorStops: [{
                                                offset: 0, color: '#ee6666' // 0% 处的颜色
                                            }, {
                                                offset: 1, color: '#fff' // 100% 处的颜色
                                            }],
                                            global: false // 缺省为 false
                                        }
                                    }
                                },
                                // {
                                //    name: '基准值',
                                //    type: 'line',
                                //    data: res.左眼裸眼视力.标准值,
                                //    symbol: 'none',
                                //    lineStyle: {
                                //       color: '#37aad3',
                                //       width: 1,
                                //    },
                                // }
                            ]
                        }
                        innitChart2('lyslChart', '度', ['右眼', '左眼', '基准值'], this.lyslobj.ages, this.lyslobj.series, '5.3', '4.0')
                        resolve();
                    })
                })
            },
            qgd() {
                return new Promise((resolve, reject) => {
                    this.$api.vipuser.qgd({ kiid: this.kiid }).then(res => {
                        this.qgdobj = {
                            ages: res.obj.age,
                            series: [
                                {
                                    name: '左眼',
                                    type: 'line',
                                    smooth: true,
                                    data: res.obj.ls,
                                    itemStyle: {
                                        color: '#698edc',
                                    },
                                    lineStyle: {
                                        color: '#698edc',
                                        width: 2,
                                    },
                                    areaStyle: {
                                        color: {
                                            type: 'linear',
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1,
                                            colorStops: [{
                                                offset: 0, color: '#698edc' // 0% 处的颜色
                                            }, {
                                                offset: 1, color: '#fff' // 100% 处的颜色
                                            }],
                                            global: false // 缺省为 false
                                        },
                                        origin: 'start',
                                    }
                                },
                                {
                                    name: '右眼',
                                    type: 'line',
                                    smooth: true,
                                    data: res.obj.rs,
                                    itemStyle: {
                                        color: '#ee6666',
                                    },
                                    lineStyle: {
                                        color: '#ee6666',
                                        width: 2,
                                    },
                                    areaStyle: {
                                        color: {
                                            type: 'linear',
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1,
                                            colorStops: [{
                                                offset: 0, color: '#ee6666' // 0% 处的颜色
                                            }, {
                                                offset: 1, color: '#fff' // 100% 处的颜色
                                            }],
                                            global: false // 缺省为 false
                                        },
                                        origin: 'start',
                                    }
                                },
                                // {
                                //    name: '基准值',
                                //    type: 'line',
                                //    data: res.左眼屈光度.屈光度标准值,
                                //    symbol: 'none',
                                //    lineStyle: {
                                //       color: '#37aad3',
                                //       width: 1,
                                //    },
                                // },
                                // {
                                //    name: '理论值',
                                //    type: 'line',
                                //    smooth: true,
                                //    data: res.左眼屈光度.屈光度理论值,
                                //    symbol: 'none',
                                //    symbol: 'none',
                                //    lineStyle: {
                                //       color: '#c18ce2',
                                //       width: 1,
                                //    },
                                // }
                            ]
                        }
                        innitChart2('qgdChart', '度', ['右眼', '左眼', '基准值', '理论值'], this.qgdobj.ages, this.qgdobj.series, 3.00, -15.00)
                        resolve();
                    })
                })
            },
            yz() {
                return new Promise((resolve, reject) => {
                    this.$api.vipuser.yz({ kiid: this.kiid }).then(res => {
                        this.yzobj = {
                            ages: res.obj.age,
                            series: [
                                {
                                    name: '左眼',
                                    type: 'line',
                                    smooth: true,
                                    data: res.obj.la,
                                    itemStyle: {
                                        color: '#698edc',
                                    },
                                    lineStyle: {
                                        color: '#698edc',
                                        width: 2,
                                    },
                                    areaStyle: {
                                        color: {
                                            type: 'linear',
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1,
                                            colorStops: [{
                                                offset: 0, color: '#698edc' // 0% 处的颜色
                                            }, {
                                                offset: 1, color: '#fff' // 100% 处的颜色
                                            }],
                                            global: false // 缺省为 false
                                        }
                                    }
                                },
                                {
                                    name: '右眼',
                                    type: 'line',
                                    smooth: true,
                                    data: res.obj.ra,
                                    itemStyle: {
                                        color: '#ee6666',
                                    },
                                    lineStyle: {
                                        color: '#ee6666',
                                        width: 2,
                                    },
                                    areaStyle: {
                                        color: {
                                            type: 'linear',
                                            x: 0,
                                            y: 0,
                                            x2: 0,
                                            y2: 1,
                                            colorStops: [{
                                                offset: 0, color: '#ee6666' // 0% 处的颜色
                                            }, {
                                                offset: 1, color: '#fff' // 100% 处的颜色
                                            }],
                                            global: false // 缺省为 false
                                        }
                                    }
                                },
                                // {
                                //    name: '基准值',
                                //    type: 'line',
                                //    data: res.左眼轴长.轴长标准值,
                                //    symbol: 'none',
                                //    lineStyle: {
                                //       color: '#37aad3',
                                //       width: 1,
                                //    },
                                // }
                            ]
                        }
                        innitChart2('yzChart', '毫米', ['右眼', '左眼', '基准值'], this.yzobj.ages, this.yzobj.series, 30, 16)
                        resolve();
                    })
                })
            },
            get_table() {
                return new Promise((resolve, reject) => {
                    console.info("daList",this.kiid );
                    this.$api.vipuser.daList({kiid:this.kiid }).then(res => {
                        console.log(res);
                        console.log("get_table");
                        this.list = res.obj
                        resolve();
                    })
                })
            },
            initImg() {
                //解决截屏时，滚动条隐藏部分不能截取问题
                // const tableWidth = this.$refs.custom_table.$refs.cTable.bodyWidth;  // 具体内容的宽度
                // const tableHeight = this.$refs.custom_table.clientHeight;  // 具体内容的高度
                // const targetDom = document.querySelector(".paper");
                // let copyDom = targetDom.cloneNode(true);
                // copyDom.style.width = tableWidth;
                // copyDom.style.height = tableHeight;
                // document.querySelector("body").appendChild(copyDom);
                const targetDom = document.querySelector(".paper");
                const options = { useCORS: true, backgroundColor: null }
                html2canvas(targetDom, options).then(
                    canvas => {
                        // document.body.appendChild(canvas);
                        this.shareImg = canvas.toDataURL("image/png");
                        this.showImg = true;
                        this.showShare = true;
                        //必须同源（访问的网站域名与服务器域名一致）才能下载
                        // const eleLink = document.createElement("a");
                        // eleLink.href = this.imgURL;    // 转换后的图片地址
                        // eleLink.download = +new Date() + "_实时采集数据";
                        // document.body.appendChild(eleLink);
                        // // 触发点击
                        // eleLink.click();
                        // // 然后移除
                        // document.body.removeChild(eleLink);
                        // document.body.removeChild(copyDom);
                    }
                );
            },
            async init() {
                // await this.get_child_info();
                // await this.get_lysl_chart();
                // await this.get_qgd_chart();
                // await this.get_yz_chart();
                // await this.get_table();
                Promise.all([
                    this.get_child_info(),
                    this.ly(),
                    this.qgd(),
                    this.yz(),
                    this.get_table(),
                ]).then(() => {
                    // setTimeout(() => {
                    // this.initImg();
                    // }, 1000);
                })
            },
            onSelect(option) {
                this.showShare = false;
            },
        },
    }
</script>
<style lang="less" scoped>
    .paper {
        width: 100%;
        min-height: 100%;
        background-color: #fbfafb;
        padding: 15px 30px;
        display: flex;
        flex-flow: column;
        align-items: center;
    }

    .header {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;

        .header-title {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .header-info {
            font-size: 18px;

            span {
                margin: 0 10px;
            }
            .add{
                position: absolute;
                top: 50px;
                right: 50px;
            }
        }
    }

    .table {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }

    table tr:nth-child(odd) {
        background-color: #dae4f8;
    }

    table,
    th,
    td {
        border: 1px solid black;
    }

    th {
        background-color: #576b95;
        color: #ffffff;
    }

    td,
    th {
        text-align: center;
        font-size: 8px;
    }

    th {
        height: 20px;
    }

    td {
        height: 40px;
    }

    td>p {
        height: 20px;
        line-height: 20px;
    }

    td>p:first-of-type {
        border-bottom: 0.1px solid black;
    }

    trs {
        background-color: #dae4f8;
    }

    .wid84 {
        width: 100px;
    }

    .wid40 {
        width: 40px;
    }

    .wid50 {
        width: 45px;
    }

    .wid70 {
        width: 40px;
    }

    .wid74 {
        width: 60px;
    }

    .wid52 {
        width: 62px;
    }

    .wid80 {
        width: 155px;
    }

    .wid100 {
        width: 120px;
    }

  

    .charts {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .chart-view {
        width: 550px;
        height: 350px;
        background-color: #ffffff;
    }

    .chart-title {
        font-size: 14.65px;
        font-weight: bold;
        text-align: center;
        padding: 0 3vw;
        margin-bottom: 10px;
    }

    .footer {
        width: 750px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 30px;
    }

    .footer>div {
        display: flex;
        flex-flow: column;
        margin-left: 10px;
        text-align: right;

        &>div {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .qrimg {
        width: 72.5px;
        height: 72.5px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .big {
        font-size: 20px;
        color: #07c160;
        font-weight: bold;
    }

    .share-img {
        /* position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -80%); */
        width: 80vw;
        z-index: 99999;
    }

 
</style>